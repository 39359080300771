.stick_follow_icon {
    top: 50%;
    left: 30px;
    width: 20px;
    height: 200px;
    position: fixed;
    margin-top: -100px;
}

.stick_follow_icon ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.stick_follow_icon svg {
    width: 3.9em;
    height: 3.9em;
    fill: var(--text-color)
}

.stick_follow_icon p {
    top: 61px;
    left: 9px;
    width: 191px;
    height: 184px;
    color: var(--text-color);
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    white-space: nowrap;
    position: relative;
    transform: rotate( -90deg);
}

.stick_follow_icon ul li {
    display: block;
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
    transition: all .3s;
}

.stick_follow_icon p:after {
    top: 9px;
    right: -48px;
    width: 150px;
    height: 1px;
    content: "";
    display: block;
    position: absolute;
    background-color: var(--text-color);
}

@media only screen and (max-width: 991px) {
    .stick_follow_icon {
        width: unset;
        height: unset;
        position: static;
        margin-top: unset;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        padding: 40px 0;
        align-items: center;
    }
    .stick_follow_icon p {
        top: unset;
        left: unset;
        width: unset;
        height: unset;
        white-space: nowrap;
        position: relative;
        transform: unset;
        font-size: 17px;
        margin-right: 65px;
    }
    .stick_follow_icon ul {
        margin-bottom: 20px;
    }
    .stick_follow_icon ul li {
        display: inline;
        margin-bottom: 29px;
        margin-right: 10px;
    }
}